/**
 * @file IpMUXPage.tsx
 * @description Page for IpMUX
 */

import Main from '../../components/Main.tsx'
import Footer from '../../components/Footer.tsx'
import Header from '../../components/Header.tsx'
import { Zoom, Fade } from 'react-reveal'
import Check from '../../components/Check.tsx'
import ipmux2 from '../../image/IpMUX2.webp'
import ipmuxImg from '../../image/IpMUX.webp'
import ipmux from '../../video/IpMUX.webm'
import React from 'react'
import '../../env.d.tsx'

const IpMUXPage = (): JSX.Element => {
  return (
    <>
      <Header page="IpMUX" />
      <Main className="py-[0!important]">
        <div className="h-[calc(100vh-75px)] min-h-[700px] grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden">
          <Fade left>
            <div className='md:justify-self-end max-w-[622px] sm:row-start-1'>
              <span className='text-[#0794d9] font-bold text-[2.8rem] sm:text-[2.6rem]'>Transmisión - IpMUX</span>
              <p className='flg text-justify text-[#ffffff]'>Aplicación para proveer funciones y control de prestaciones como: [<span>Programas</span> <span>Disponibles</span> <span>Paquetes</span> <span>Comerciales</span> <span>Estándar</span> <span>Premium</span> <span>Adultos</span> <span>Terminales</span> <span>Sesiones</span> <span>Gratis</span> <span>Contratos</span> <span>Suscriptores</span> <span>Aprovisionamiento</span> <span>Conexiones</span> <span>Desconexiones</span> <span>Facturación</span> <span>Mensajería</span> <span>Monitoreo</span>], para permitir realizar la interacción y administración entre la plataforma de origen de contenido para servicios <span>IpTV</span> <span>OTT</span>, con los suscriptores finales las empresas de venta de contenido.</p>
            </div>
          </Fade>
          <Zoom><i className='md:justify-self-start md:ml-[100px] icon i-ipmux'></i></Zoom>
        </div>
        <div className="grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden py-[80px] text-[.9rem] sm:gap-[50px] md:rounded-br-[200px]">
          <Fade left><img src={ipmux2} alt="IpMUX" className='md:mr-[100px] md:justify-self-end w-[35vw] sm:w-full' /></Fade>
          <Fade right>
            <div className='md:justify-self-start max-w-[622px] sm:row-start-1'>
              <p className='text-[#0794d9] font-bold text-[2.8rem] sm:text-[2.6rem]'>Manejo Operativo</p>
              <p className='font-bold text-[2.8rem] sm:text-[2.6rem] text-[#ffffff] flg'><span>IpMUX</span></p>
              <ul className='grid grid-cols-2 gap-[10px] xs:grid-cols-1 text-[#ffffff]'>
                <Check>Portal <span>IpMUX</span></Check>
                <Check>Configuración modo <span>IpTV</span> <span>OTT</span> <span>MULTICAST</span> <span>UNICAST</span></Check>
                <Check>Parametrización operacional y funcional</Check>
                <Check><span>Creación</span> <span>Borrado</span> <span>Edición</span> de <span>Programas</span></Check>
                <Check><span>Creación</span> <span>Borrado</span> <span>Edición</span> de <span>Planes</span> <span>Comerciales</span></Check>
                <Check><span>Creación</span> <span>Borrado</span> <span>Edición</span> de <span>Contratos</span> <span>Suscriptores</span></Check>
                <Check><span>Creación</span> <span>Borrado</span> <span>Edición</span> de <span>Pruebas</span> <span>Gratis</span></Check>
                <Check><span>Creación</span> <span>Borrado</span> <span>Edición</span> de <span>Dispositivos</span></Check>
                <Check><span>Creación</span> <span>Borrado</span> <span>Edición</span> de <span>Sesiones</span> o <span>Cuentas</span></Check>
                <Check>Modo <span>Configuración</span> acceso suscriptores <span>ID</span> <span>MAC</span> <span>SESION</span></Check>
                <Check>Mensajería masiva e individualo</Check>
                <Check><span>Aprovisionamiento</span> <span>Monitoreo</span> <span>Gestión</span> <span>Actualizaciones</span> <span>Sincronización</span></Check>
                <Check>Cambios en línea para suscriptores</Check>
                <Check>Temporizadores para pruebas gratis</Check>
                <Check><span>DRM</span> <span>Tokens</span> <span>Seguridad</span> para transmisión de contenido legitimo o abierto</Check>
              </ul>
            </div>
          </Fade>
        </div>
        <div className='flex flex-col justify-center items-center w-full mt-[20px] px-[10px] py-[80px]'>
          <span className='text-[2.6rem] sm:text-[2rem] font-bold text-center text-[#ffffff]'>MANEJO DE CONTENIDO</span>
          <p className='text-justify max-w-[1400px] text-[#ffffff]'>El IpMUX, es un sofisticado sistema para organizar todos los contenidos disponibles en cada una de sus naturalezas y los dispone en cada dispositivo de cada usuario en base a todas las reglas definidas comercialmente y contractualmente con todas las empresas que conforman la cadena de valor: productoras, distribuidoras, plataforma y operadoras.</p>
          <span className='flg text-[2.6rem] sm:text-[2rem] font-bold text-center mt-[30px] text-[#ffffff]'>Diagramación <span>IpMUX</span></span>
          <video playsInline muted autoPlay loop poster={ipmuxImg} className="max-w-[80%] sm:max-w-full">
            <source src={ipmux} type="video/webm" />
          </video>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default IpMUXPage