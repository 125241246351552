/**
 * @file Card.tsx
 * @description Card component
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { FaAngleRight } from 'react-icons/fa/index.js'

const Card = (props): JSX.Element => {
  return (
    <>
      <div className={(props.color ? 'border-' + props.color + ' ' : 'border-cBlue ') + 'border-[4px] max-w-[384px] w-full rounded-[15px] bg-[#0a0a0b] p-[30px] flex flex-col justify-between hover:scale-[1.05] transition'}>
        <div>
          <div className='flex justify-between items-center mb-[10px] sm:flex-wrap'>
            <span className='font-SKPayidar text-[#ffffff] text-[1.5rem] flex items-center relative'>{props.title}</span>
            <span className={(props.color ? 'text-' + props.color + ' ' : 'text-cBlue ') + ' text-[1rem]'}>{props.title2}</span>
          </div>
          <div className='w-full'>
            <img src={props.img} alt={props.title} />
          </div>
          <div className='text-[#ffffff] text-[1rem] mb-[30px] '>
            {props.children}
          </div>
        </div>
        <div className='flex justify-end'>
          <Link className={(props.color ? 'bg-' + props.color + ' ' : 'bg-cBlue ') + ' rounded-[64px] w-[56px] p-[8px] text-center text-[#f3f3f3] flex justify-center items-center text-[22px]'} to={props.link}><FaAngleRight /></Link>
        </div>
      </div>
    </>
  )
}

export default Card