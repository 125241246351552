/**
 * @file LegalWarningPage.tsx
 * @description Legal Warning Page
 */

import Main from '../components/Main.tsx'
import Footer from '../components/Footer.tsx'
import Header from '../components/Header.tsx'
import { Link } from 'react-router-dom'
import React from 'react'

const LegalWarningPage = (): JSX.Element => {
  const handleClick = (e) => {
    window.location.href = "mailto:legal@ioplay.co"
    e.preventDefault()
  }
  return (
    <>
      <Header />
      <Main className="bg-1 flex flex-col justify-center items-center py-[20px]">
        <div className='max-w-[1000px] bg-[#000000] p-[20px] text-[#f3f3f3] border-[1px] border-[#272727] rounded-[10px] text-justify'>
          <span className='text-[2.6rem] font-bold'>Aviso Legal</span>
          <p className='flg mb-[15px]'>De acuerdo con la ley estatutaria 1581 de 2012 y el Decreto 1377 de 2013 de la república de Colombia, la presente política de protección de datos personales y privacidad regula la recolección, almacenamiento, tratamiento, protección y administración de los datos que, I<em>o</em>PLAY NIT 9016615431, obtenga de sus clientes, proveedores, empleados y demás interesados, por diferentes medios, tales como el contacto personal o telefónico, vía correo electrónico, los sitios web de la compañía y otros medios habilitados para tal propósito.</p>
          <p className='flg mb-[15px]'>I<em>o</em>PLAY, se reserva el derecho a modificar la política de protección de datos personales y privacidad, con el fin de adecuarla a cambios en la legislación y jurisprudencias y adaptarla a prácticas de industria. En ese evento, anunciará en su sitio web, o por otros medios adecuados, la introducción de tales cambios con la debida anticipación.</p>
          <p className='flg mb-[15px]'>La información que libre y voluntariamente los clientes, proveedores, empleados e interesados suministren a I<em>o</em>PLAY por cualquier medio, es introducida en nuestras bases de datos, de las cuales I<em>o</em>PLAY es responsable y titular.</p>
          <p className='flg mb-[15px]'>I<em>o</em>PLAY trata los datos personales de forma adecuada y segura, cumpliendo todos los requisitos de ley. La información recopilada sobre clientes, proveedores e interesados se utiliza, bajo autorización de estos, con los siguientes propósitos:</p>
          <ul className='list-disc ml-[1rem] mb-[15px]'>
            <li>Prestar al titular de la información los servicios de asistencia, consultoría y soporte necesarios para cumplir las obligaciones emanadas de nuestras relaciones comerciales.</li>
            <li className='flg'>Realizar las gestiones necesarias para dar cumplimiento a las obligaciones inherentes a los servicios y productos contratados con I<em>o</em>PLAY.</li>
            <li>Ofrecer información sobre nuevos productos y servicios, así como de oportunidades comerciales.</li>
            <li>Realizar, actividades de mercadeo, promoción, publicidad, compras, facturación, cobranza, recaudo, pagos, mejoras en el servicio y producto, consultas, controles, verificaciones, alianzas, acuerdos, así como cualquier otra actividad relacionada con nuestros servicios y productos actuales y futuros.</li>
            <li>Evaluar la calidad de nuestros productos y servicios y el nivel de satisfacción de nuestros clientes, proveedores e interesados, mediante encuestas de satisfacción y otros mecanismos que se habiliten para el propósito.</li>
          </ul>
          <p className='flg mb-[15px]'>Las actividades anteriormente mencionadas, para las cuales se utilizan los datos personales, son realizadas por I<em>o</em>PLAY o por quienes este contrate para ejercer su representación.</p>
          <p className='flg mb-[15px]'>Los datos personales que I<em>o</em>PLAY recolecta de sus empleados, proveedores, clientes e interesados tienen que ver con:</p>
          <p className='flg mb-[15px]'><b>Interesados en relaciones comerciales,</b> personas que por su propia voluntad nos contactan a través de nuestros sitios web, correo electrónico, llamadas telefónicas u otros medios, con el fin de solicitar información acerca de nuestros productos o servicios, evaluar posibilidades de alianzas comerciales, proveerle a I<em>o</em>PLAY sus productos y servicios, y en fin evaluar la posibilidad de desarrollar algún tipo de relación con la compañía. En estos casos la información que se solicita es de carácter básico, tal la relacionada con la identificación de la persona y compañía a la que pertenece, cargo, correo electrónico, teléfono, ciudad y asunto. En ocasiones, al ser contactados a través de nuestro sitio web, nuestros servidores obtienen, para propósitos estadísticos, datos en relación con el sistema operativo que utiliza la persona que nos contacta, la versión y el tipo de browser y la dirección IP.</p>
          <p className='flg mb-[15px]'><b>Clientes, proveedores y otros</b> cuando se establece un vínculo comercial definitivo, I<em>o</em>PLAY recolecta la información necesaria para gestionar de manera adecuada la relación, de acuerdo con los parámetros en esta política establecidos y cumpliendo todos los requisitos de ley.</p>
          <p className='flg mb-[15px]'><b>Interesados en vincularse laboralmente:</b> Personas que por su propia voluntad envían su hoja de vida a la compañía, con el objeto de ser tenidas en cuenta en los procesos de selección. La información suministrada solo la utilizan quienes tengan que ver en los procesos de selección, reclutamiento y contratación. I<em>o</em>PLAY no comparte la información recolectada con ninguna persona natural o jurídica ajena a la compañía.</p>
          <p className='flg mb-[15px]'><b>Empleados.</b> La información sobre los empleados es estrictamente confidencial y se ajusta a los requisitos de la ley colombiana. Solo pueden utilizarla quienes, en razón de su cargo, tengan que ver con la gestión de la relación laboral. I<em>o</em>PLAY no hace púbico datos o información relacionada con los empleados a personas ajenas a la relación laboral o a entidades y personas ajenas a la compañía.</p>
          <p className='flg mb-[15px]'>I<em>o</em>PLAY espera que la información que le sea suministrada sea veraz. No acepta ni trata información y datos provenientes de menores de 14 años que no hayan sido recabados con el consentimiento de los padres o representantes legales.</p>
          <p className='flg mb-[15px]'>I<em>o</em>PLAY no hace pública la información personal de los clientes, proveedores, empleados e interesados, quienes al aceptar esta política reconocen que suministran la información de manera voluntaria, y autorizan que sea utilizada en los términos aquí contenidos. Usted tiene la posibilidad de acceder en cualquier momento a sus datos personales y el derecho de solicitar expresamente, en cualquier momento, su corrección, actualización o supresión, en los términos establecidos por la ley 1581 de 2012 y el decreto 1377 de 2013. Para el propósito, debe enviar una solicitud al correo electrónico <Link className="text-[#0794d9]" to="#" onClick={handleClick}>legal@ioplay.co</Link>, y en respuesta le enviaremos el paso a seguir según la solicitud realizada.</p>
          <p>Si tiene dudas o inquietudes acerca de esta política, por favor envíe un correo electrónico a <Link className="text-[#0794d9]" to="#" onClick={handleClick}>legal@ioplay.co</Link>.</p>
        </div>
      </Main>
      <Footer page="Aviso Legal" />
    </>
  )
}

export default LegalWarningPage