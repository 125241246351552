/**
  * @file App.tsx
  * @description Main component of the application
  * TODO: REVISAR DONDE SE HAGA LLAMADO AL SERVER
  * TODO: CAMBIO DE VISTAS SP
  */

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import HomePage from './pages/HomePage.tsx'
import TvMUXPage from './pages/IpTV_OTT/TvMUXPage.tsx'
import IpMUXPage from './pages/IpTV_OTT/IpMUXPage.tsx'
import APPPage from './pages/IpTV_OTT/APPPage.tsx'
import SuscriberTerminalsPage from './pages/IpTV_OTT/SuscriberTerminalsPage.tsx'
import HeadboardPage from './pages/IpTV_OTT/HeadboardPage.tsx'
import IpguiaPage from './pages/IpTV_OTT/IpguiaPage.tsx'
import InfoboxPage from './pages/Aplicaciones/InfoboxPage.tsx'
import CableboxPage from './pages/Aplicaciones/CableboxPage.tsx'
import ApiPage from './pages/Aplicaciones/ApiPage.tsx'
import ProvisioningPage from './pages/ProvisioningPage.tsx'
import AboutUsPage from './pages/AboutUsPage.tsx'
import ContactUsPage from './pages/ContactUsPage.tsx'
import TechnicalAssistancePage from './pages/TechnicalAssistancePage.tsx'
import LegalWarningPage from './pages/LegalWarningPage.tsx'
// import DemoPage from './pages/DemoPage.tsx'
// import DemoAdminPage from './pages/DemoAdminPage.tsx'
import NubeboxPage from './pages/Aplicaciones/NubeboxPage.tsx'
import DvMUXPage from './pages/IpTV_OTT/DvMUXPage.tsx'
import NotFoundPage from './pages/NotFoundPage.tsx'
import { Routes, Route } from 'react-router-dom'
import { FacebookRedirect, InstagramRedirect, XRedirect, AndroidAppRedirect, LGAppRedirect } from './pages/Redirect.tsx'
import React from 'react'

const App = (): JSX.Element => {
  const location = useLocation()
  const [cookies, setCookies] = useState(false)
  useEffect(() => {
    if (localStorage.getItem('cookies')) {
      setCookies(true)
      document.body.classList.remove('no-scroll')
    } else {
      document.body.classList.add('no-scroll')
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
    let page = location.pathname.replace('/', '')
    page = page !== '' ? page : 'Inicio'
    document.title = 'IoPLAY - ' + page;
  }, [location.pathname])
  const handleClick = () => {
    localStorage.setItem('cookies', 'true')
    setCookies(true)
    document.body.classList.remove('no-scroll')
  }
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/TvMUX' element={<TvMUXPage />} />
        <Route path='/IpMUX' element={<IpMUXPage />} />
        <Route path='/DvMUX' element={<DvMUXPage />} />
        <Route path='/APP' element={<APPPage />} />
        <Route path='/Suscriptores' element={<SuscriberTerminalsPage />} />
        <Route path='/Cabecera' element={<HeadboardPage />} />
        <Route path='/IpGUIA' element={<IpguiaPage />} />
        <Route path='/InfoBOX' element={<InfoboxPage />} />
        <Route path='/CableBOX' element={<CableboxPage />} />
        <Route path='/NubeBOX' element={<NubeboxPage />} />
        <Route path='/API' element={<ApiPage />} />
        <Route path='/Aprovisionamiento' element={<ProvisioningPage />} />
        <Route path='/SobreIoPLAY' element={<AboutUsPage />} />
        {/* <Route path='/SolicitarDemo' element={<DemoPage />} /> */}
        {/* <Route path='/DemoAdmin' element={<DemoAdminPage />} /> */}
        <Route path='/AvisoLegal' element={<LegalWarningPage />} />
        <Route path='/Contacto' element={<ContactUsPage />} />
        <Route path='/AsistenciaTecnica' element={<TechnicalAssistancePage />} />
        <Route path='/AndroidApp' element={<AndroidAppRedirect />} />
        <Route path='/LGApp' element={<LGAppRedirect />} />
        <Route path='/Facebook' element={<FacebookRedirect />} />
        <Route path='/X' element={<XRedirect />} />
        <Route path='/Twitter' element={<XRedirect />} />
        <Route path='/Instagram' element={<InstagramRedirect />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      {
        !cookies ? <div className='w-full h-full fixed top-0 left-0 flex justify-center items-end z-[100] bg-[#00000020] p-[25px]'>
          <div className='w-full min-h-[100px] bg-[#fff] rounded-[16px] p-[15px] flex justify-between items-start sm:flex-col gap-[10px] sm:items-center'>
            <div>
              <span className='text-[1.5rem] font-bold'>Cookies</span>
              <p className='text-[1.1rem]'>Este sitio web utiliza cookies y seguimiento de terceros para garantizar que obtenga la mejor experiencia y mejore continuamente nuestros servicios.</p>
            </div>
            <button onClick={handleClick} className='my-auto bg-[#0794d9] text-[#fff] text-[1.2rem] rounded-[10px] min-w-[190px] px-[25px] py-[10px]'>Aceptar cookies</button>
          </div>
        </div> : null
      }
    </>
  )
}

export default App