/**
 * @file Check.tsx
 * @description Check component
 */

import React from 'react'
import { FaCheck } from 'react-icons/fa/index.js'

const Check = (props): JSX.Element => {
  return (
    <>
      <li className='flg check'><FaCheck className='inline-block text-[#0794d9] text-[1.1rem] border-[#0794d9] border-[2px] p-[2px] rounded-[8px] box-content mr-[5px]' /> {props.children}</li>
    </>
  )
}

export default Check