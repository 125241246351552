/**
 * @file Dropdown.tsx
 * @description Dropdown component
 */

import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import { FaAngleDown } from 'react-icons/fa/index.js'

const Dropdown = (props): JSX.Element => {
  const [show, setShow] = useState(false)
  const showDropdown = () => {
    setShow(!show)
  }
  const hideDropdown = () => {
    setShow(false)
  }
  const style = useSpring({
    rotate: show ? '180deg' : '0',
    config: { duration: 150 }
  })

  return (
    <li onMouseEnter={showDropdown} onMouseLeave={hideDropdown} className={(props.items.includes(props.active) ? "active " : "") + "itemHeader"}>
      <span className='cursor-default flex items-center'>{props.children}<animated.span style={style}><FaAngleDown className='text-[#1f87d6]' /></animated.span></span>
      <DropdownMenu show={show} active={props.active}>
        {
          props.items.map((element, index) => {
            return <li key={index} className={(props.active === element ? "text-[#0794d9] " : "text-[#f3f3f3] ") + "hover:text-[#0794d9] relative"}><Link className='bg-[#121212] border-[1px] border-[#262626] rounded-[6px] p-[6px] w-[100%] flex items-center' to={props.itemsLinkTo[index]}>
              <Switch test={props.itemsArrow[index]}>
                <CustomFaAngleDown value='0' className='text-cBlue text-[25px] -rotate-90' />
                <CustomFaAngleDown value='1' className='text-cRed text-[25px] -rotate-90' />
                <CustomFaAngleDown value='2' className='text-cPurple text-[25px] rotate-90' />
                <CustomFaAngleDown value='3' className='text-cGreen text-[25px] rotate-90' />
              </Switch>
              {element}</Link></li>
          })
        }
      </DropdownMenu>
    </li>
  )
}

const DropdownMenu = (props): JSX.Element => {
  const style = useSpring({
    display: props.show ? 'flex' : 'none',
    translateY: props.show ? '0' : '-10%',
    opacity: props.show ? '1' : '0',
    config: { duration: 150 }
  }
  )
  return (
    <animated.ul className="flex absolute bg-[#000000] p-[14px] min-w-[250px] flex-col origin-top z-10 top-[100%] gap-[10px] rounded-[10px] border-[1px] border-[#262626]" style={style}>
      {props.children}
    </animated.ul>
  )
}

const CustomFaAngleDown = ({ value, className }) => <FaAngleDown className={className} />;
const Switch = props => {
  const { test, children } = props
  return children.find(child => {
    return child.props.value === test
  })
}

export default Dropdown
export { DropdownMenu }