/**
 * @file NotFoundPage.tsx
 * @description Not Found Page
 */

import Main from '../components/Main.tsx'
import Footer from '../components/Footer.tsx'
import Header from '../components/Header.tsx'
import { FaExclamationCircle } from 'react-icons/fa/index.js'
import React from 'react'

const NotFoundPage = (): JSX.Element => {
  return (
    <>
      <Header />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-375px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>No se ha encontrado la pagina</span>
        <FaExclamationCircle className='text-[3rem]' />
      </Main>
      <Footer />
    </>
  )
}

export default NotFoundPage