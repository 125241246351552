/**
 * @file HomePage.tsx
 * @description Home page
 */

import Main from '../components/Main.tsx'
import Footer from '../components/Footer.tsx'
import Header from '../components/Header.tsx'
import Card from '../components/Card.tsx'
import portada from '../video/portada.webm'
import portadaImg from '../image/portada.webp'
import click from '../image/click.webp'
import TvMUX from '../image/TvMUX.webp'
import ePaycoImg from '../image/ePayco.webp'
import SuperGIROSImg from '../image/SuperGIROS.webp'
import efectyImg from '../image/efecty.webp'
import PayPalImg from '../image/PayPal.webp'
import SmartOLTImg from '../image/SmartOLT.webp'
import amazonImg from '../image/amazon.webp'
import AzureImg from '../image/Azure.webp'
import PROXMOXImg from '../image/PROXMOX.webp'
import vmwareImg from '../image/vmware.webp'
import ubuntuImg from '../image/ubuntu.webp'
import redhatImg from '../image/redhat.webp'
import slesImg from '../image/sles.webp'
import windowsImg from '../image/windows.webp'
import NUTANIXImg from '../image/NUTANIX.webp'
import { FaAngleDown } from 'react-icons/fa/index.js'
import React from 'react'
import '../env.d.tsx'

const HomePage = (): JSX.Element => {
  return (
    <>
      <Header />
      <Main className="py-[0!important]">
        <div className='w-full h-[calc(100vh_-_75px)] flex justify-center items-center border-b-[1px] border-b-[#1f87d6] flex-col text-[3rem] font-bold relative'>
          <div className='absolute top-0 left-0 w-full h-full flex justify-start items-center sm:justify-center z-10'>
            <div className='lg:ml-[18vw] md:ml-[5vw] text-center text-[#f3f3f3]'>
              <span className='flg'><span>IpTV</span> <span>OTT</span> A UN CLICK <img src={click} alt="Click" className='h-[40px] inline-block' /></span>
            </div>
          </div>
          <video playsInline muted loop autoPlay poster={portadaImg} className="h-full w-full object-cover absolute top-0 left-0 z-0 brightness-50 sm:opacity-50 sm:object-right">
            <source src={portada} type="video/webm" />
          </video>
        </div>
        <div className='flex flex-wrap justify-center gap-[26px] sm:p-[4px] md:p-[17px_120px_40px] m-[0_auto] w-full'>
          <Card title={<>T<span className="text-cRed">v</span>MU<span className='flex absolute h-[36px] items-center right-[8px] text-[28px]'><FaAngleDown className='rotate-[-90deg] absolute z-[2] text-cRed' /><FaAngleDown className='rotate-[-90deg] absolute text-[32px] text-[#0a0a0b] z-[1]' /><FaAngleDown className='rotate-[90deg] absolute left-[8px] z-[0]' /></span></>} title2='Transcodificación' color="cRed" img={TvMUX} link="/TvMUX">
            <div className='flex gap-[10px] mt-[10px] items-center h-[40px]'>
              <span className='text-[#ffffff] text-[1rem] flex items-center relative'>
                <span className='flex items-center'><FaAngleDown className='rotate-[-90deg] text-cRed text-[25px]' /><p className="flgR"><span>Multicast</span> <span>Unicast</span></p></span>
              </span>
            </div>
            <p className="flgR grid grid-cols-[1fr_1fr_1fr_1fr_1fr] gap-[4px]">
              <span>File</span>
              <span>RIST</span>
              <span>RTP</span>
              <span>SRT</span>
              <span>UDP</span>
              <span>HLS</span>
              <span>URL</span>
              <span>RTMP</span>
              <span>RTSP</span>
              <span>HTTP</span>
            </p>
          </Card>
          <Card title={<>I<span className="text-cPurple">p</span>MU<span className='flex absolute h-[36px] items-center right-[8px] text-[28px]'><FaAngleDown className='rotate-[-90deg] absolute z-[2]' /><FaAngleDown className='rotate-[-90deg] absolute text-[32px] text-[#0a0a0b] z-[1]' /><FaAngleDown className='rotate-[90deg] absolute left-[8px] z-[0] text-cPurple' /></span></>} title2='Transmisión' color="cPurple" img={TvMUX} link="/IpMUX">
            <div className='flex gap-[10px] mt-[10px] items-center h-[40px]'>
              <span className='text-[#ffffff] text-[1rem] flex items-center relative'>
                <span className='flex items-center'><FaAngleDown className='rotate-[90deg] text-cPurple text-[25px]' />Gestión Contenido</span>
              </span>
            </div>
            <p className="flgP grid grid-cols-[1fr_1fr_1fr] gap-[4px]"><span>Planes</span> <span>Programas</span> <span>Sesiones</span> <span>TvBOX</span> <span>Monitoreo</span> <span>Apps</span></p>
          </Card>
          <Card title={<>D<span className="text-cGreen">v</span>MU<span className='flex absolute h-[36px] items-center right-[8px] text-[28px]'><FaAngleDown className='rotate-[-90deg] absolute z-[2]' /><FaAngleDown className='rotate-[-90deg] absolute text-[32px] text-[#0a0a0b] z-[1]' /><FaAngleDown className='rotate-[90deg] absolute left-[8px] z-[0] text-cGreen' /></span></>} title2='Modulación' color="cGreen" img={TvMUX} link="/DvMUX">
            <div className='flex gap-[10px] mt-[10px] items-center h-[40px]'>
              <span className='text-[#ffffff] text-[1rem] flex items-center relative'>
                <span className='flex items-center'><FaAngleDown className='rotate-[90deg] text-cGreen text-[25px]' />Transpondedor</span>
              </span>
            </div>
            <p className="flgG gap-[4px]"><span>DvBC<span className='text-[.8rem]'>2</span></span> <span>DvBT<span className='text-[.8rem]'>2</span></span> <span>DvBS<span className='text-[.8rem]'>2</span></span></p>
          </Card>
          <Card title={<>Inf<span className="text-cBlue">o</span>BO<span className='flex absolute h-[36px] items-center right-[8px] text-[28px]'><FaAngleDown className='rotate-[-90deg] absolute z-[2] text-cBlue' /><FaAngleDown className='rotate-[-90deg] absolute text-[32px] text-[#0a0a0b] z-[1]' /><FaAngleDown className='rotate-[90deg] absolute left-[8px] z-[0]' /></span></>} title2='ERP' color="cBlue" img={TvMUX} link="/InfoBOX">
            <div className='flex gap-[10px] mt-[10px] items-center h-[40px]'>
              <span className='text-[#ffffff] text-[1rem] flex items-center relative'>
                <span className='flex items-center'><FaAngleDown className='rotate-[-90deg] text-cBlue text-[25px]' />Gestión Empresarial Modular</span>
              </span>
            </div>
            <p className='flgB grid grid-cols-[1fr_1fr_1fr] gap-[4px]'><span>Comercial</span> <span>Financiero</span> <span>Operacional</span> <span>Administrativo</span> <span>Inferfaces</span> <span>Contables</span> <span>Reporteria</span></p>
            <div className='flex gap-[10px] items-center h-[40px]'>
              <span className='text-[#ffffff] text-[1rem] flex items-center relative'>
                <span className='flex items-center'><FaAngleDown className='rotate-[-90deg] text-cBlue text-[25px]' />API</span>
              </span>
            </div>
            <p className='flgB grid grid-cols-[auto_1fr_auto_1fr] gap-[4px]'><img src={SmartOLTImg} alt="SmartOLT" width={25} className='inline' /> <span>SmartOLT</span> <img src={SuperGIROSImg} alt="SuperGIROS" width={25} className='inline' /> <span>SuperGIROS</span> <img src={ePaycoImg} alt="ePayco" width={25} className='inline' /> <span>ePayco</span> <img src={efectyImg} alt="efecty" width={25} className='inline' /> <span>efecty</span> <img src={PayPalImg} alt="PayPal" width={25} className='inline' /> <span>PayPal</span></p>
          </Card>
          <Card title={<>Cabl<span className="text-cBlue">e</span>BO<span className='flex absolute h-[36px] items-center right-[8px] text-[28px]'><FaAngleDown className='rotate-[-90deg] absolute z-[2] text-cBlue' /><FaAngleDown className='rotate-[-90deg] absolute text-[32px] text-[#0a0a0b] z-[1]' /><FaAngleDown className='rotate-[90deg] absolute left-[8px] z-[0]' /></span></>} title2='Aprovisionamiento' color="cBlue" img={TvMUX} link="/CableBOX">
            <div className='flex gap-[10px] mt-[10px] items-center h-[40px]'>
              <span className='text-[#ffffff] text-[1rem] flex items-center relative'>
                <span className='flex items-center'><FaAngleDown className='rotate-[-90deg] text-cBlue text-[25px]' />API</span>
              </span>
            </div>
            <p className='flg grid grid-cols-[1fr_1fr_1fr_1fr] gap-[4px]'><span>DOCSIS</span> <span>EoC</span> <span>DvBC</span> <span>WISP</span> <span>EPON</span> <span>GPON</span> <span>IpTV</span> <span>OTT</span></p>
          </Card>
          <Card title={<>Nub<span className="text-cBlue">e</span>BO<span className='flex absolute h-[36px] items-center right-[8px] text-[28px]'><FaAngleDown className='rotate-[-90deg] absolute z-[2] text-cBlue' /><FaAngleDown className='rotate-[-90deg] absolute text-[32px] text-[#0a0a0b] z-[1]' /><FaAngleDown className='rotate-[90deg] absolute left-[8px] z-[0]' /></span></>} title2='Virtualización' color="cBlue" img={TvMUX} link="/NubeBOX">
            <div className='flex gap-[10px] mt-[10px] items-center h-[40px]'>
              <span className='text-[#ffffff] text-[1rem] flex items-center relative'>
                <span className='flex items-center'><FaAngleDown className='rotate-[-90deg] text-cBlue text-[25px]' /><p className="flgB"><span>VM</span> <span>VPS</span> <span>LXC</span></p></span>
              </span>
            </div>
            <p className='flgB grid grid-cols-[auto_1fr_auto_1fr] gap-[4px]'><img src={AzureImg} alt="Azure" width={25} className='inline' /> <span>Azure</span> <img src={PROXMOXImg} alt="PROXMOX" width={25} className='inline' /> <span>PROXMOX</span> <img src={vmwareImg} alt="vmware" width={25} className='inline' /> <span>vmware</span> <img src={amazonImg} alt="amazon" width={25} className='inline' /> <span>amazon</span> <img src={NUTANIXImg} alt="NUTANIX" width={25} className='inline' /> <span>NUTANIX</span></p>
            <div className='flex gap-[10px] items-center h-[40px]'>
              <span className='text-[#ffffff] text-[1rem] flex items-center relative'>
                <span className='flex items-center'><FaAngleDown className='rotate-[-90deg] text-cBlue text-[25px]' />Soporte</span>
              </span>
            </div>
            <p className='flgB grid grid-cols-[auto_1fr_auto_1fr] gap-[4px]'><img src={windowsImg} alt="Windows" width={25} className='inline' /> <span>Windows</span> <img src={ubuntuImg} alt="Ubuntu" width={25} className='inline' /> <span>Ubuntu</span> <img src={slesImg} alt="SLES" width={25} className='inline' /> <span>SLES</span> <img src={redhatImg} alt="RedHat" width={25} className='inline' /> <span>RedHat</span></p>
          </Card>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default HomePage