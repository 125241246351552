/**
 * @file Header.tsx
 * @description Header component
 */

import Dropdown from './Dropdown.tsx'
import { Link } from 'react-router-dom'
import Accordion from './Accordion.tsx'
import logo from '../image/logo.webp'
import { FaBars, FaTimes } from 'react-icons/fa/index.js'
import { useState } from 'react'
import React from 'react'
import '../env.d.tsx'

const Header = (props): JSX.Element => {
  const [show, setShow] = useState(false)
  const toggleMobileNav = () => {
    setShow(!show)
  }
  const [expanded, setExpanded] = useState<string | false>(false)
  const handleClick = (expand: string) => () => {
    setExpanded(expanded === expand ? false : expand)
  }
  return (
    <>
      <header className="w-screen h-[75px] sm:absolute md:fixed flex flex-row items-center md:px-[10%] lg:px-[15%] sm:px-[30px] top-0 justify-center z-[100] bg-[#121212] border-b-[1px] border-b-[#1f87d6]">
        <div className='w-[70px] relative'>
          <Link to='/' className='contents'>
            <img src={logo} alt="IoPLAY" width="60" height="60" />
          </Link>
        </div>
        <div className='w-[calc(100%-70px)] relative sm:flex sm:items-center h-full'>
          <ul className='flex flex-row items-center justify-center md:gap-[10px] lg:gap-[30px] sm:hidden h-full'>
            <Dropdown itemsArrow={["1", "2", "3", "0", "0", "0", "0"]} items={["TvMUX", "IpMUX", "DvMUX", "APP", "Suscriptores", "Cabecera", "IpGUIA"]} itemsLinkTo={["/TvMUX", "/IpMUX", "/DvMUX", "/APP", "/Suscriptores", "/Cabecera", "/IpGUIA"]} active={props.page}><p className='flg flex items-center'><span>IpTV</span> <span>OTT</span></p></Dropdown>
            <li className={(props.page === "Sobre IoPLAY" ? "active " : "") + "itemHeader"}><Link to="/SobreIoPLAY"><p className='flg'>SOBRE I<em>o</em>PLAY</p></Link></li>
            {/* <li className={(props.page === "Solicitar Demo" ? "translate-y-[-5px] " : "") + 'hover:translate-y-[-5px] transition'}><Link to="/SolicitarDemo" className='bg-[#0794d9] p-[.7rem_.9rem] rounded-[50px_50px_50px_0] text-[#fff]'>SOLICITAR DEMO EN LINEA</Link></li> */}
          </ul>
          <button className='md:hidden absolute right-0 text-[30px]' onClick={toggleMobileNav}><FaBars /></button>
        </div>
      </header>
      <div className={(show ? "block " : "hidden ") + " w-screen h-screen fixed bg-[#2f353c] top-0 z-[101] overflow-auto md:hidden"}>
        <div className='absolute top-0 right-0 m-[20px]'>
          <button className='md:hidden absolute right-0 text-[30px] text-[#fff]' onClick={toggleMobileNav}><FaTimes /></button>
        </div>
        <div className='text-[#fff] flex justify-center text-center w-full mt-[60px] flex-col h-min'>
          <Link to="/"><span className='text-[2.6rem] flg'>I<em>o</em>PLAY</span></Link>
        </div>
        <div className='text-[#fff] flex justify-center text-center w-full mt-[60px] flex-col h-min items-center'>
          <ul className='w-fit text-left'>
            <Accordion onClick={handleClick("1")} expanded={expanded === '1'} items={["TvMUX", "IpMUX", "DvMUX", "APP", "Suscriptores", "Cabecera", "IpGUIA"]} itemsLinkTo={["/TvMUX", "/IpMUX", "/DvMUX", "/APP", "/Suscriptores", "/Cabecera", "/IpGUIA"]} active={props.page} ><p className='flg'><span>IpTV</span> <span>OTT</span></p></Accordion>
            <li className='text-[1.6rem]'><Link to="/SobreIoPLAY" className={(props.page === "Sobre IoPLAY" ? "text-[#0794d9]" : "")}><p className='flg'>Sobre I<em>o</em>PLAY</p></Link></li>
            {/* <li className='mt-[30px]'><Link to="/SolicitarDemo" className='bg-[#0794d9] p-[.7rem_.9rem] rounded-[50px_50px_50px_0] text-[#fff]'>SOLICITAR DEMO EN LINEA</Link></li> */}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Header