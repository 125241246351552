/**
 * @file HeadboardPage.tsx
 * @description Page for Cabecera
 */

import Main from '../../components/Main.tsx'
import Footer from '../../components/Footer.tsx'
import Header from '../../components/Header.tsx'
import cabeceraImg from '../../image/cabecera.webp'
import cabecera from '../../video/cabecera.webm'
import x86_64 from '../../video/x86_64.webm'
import acelerado from '../../video/acelerado.webm'
import x86_64Img from '../../image/x86_64.webp'
import aceleradoImg from '../../image/acelerado.webp'
import logosCabecera1 from '../../image/logosCabecera1.webp'
import logosCabecera2 from '../../image/logosCabecera2.webp'
import React from 'react'
import '../../env.d.tsx'

const HeadboardPage = (): JSX.Element => {
  return (
    <>
      <Header page="Cabecera" />
      <Main className="py-[0!important]">
        <div className='h-[calc(100vh-75px)] min-h-[400px] flex justify-center items-center flex-col text-[#fff] text-[2.8rem] font-bold w-full text-center px-[10px]'>
          <span className='z-10'>MODELO OPERACIONAL</span>
          <span className='flg z-10'>CABECERA <span>IpTV</span> <span>OTT</span></span>
          <video playsInline muted loop autoPlay poster={cabeceraImg} className="h-full w-full object-cover brightness-50 absolute top-0 left-0 z-0">
            <source src={cabecera} type="video/webm" />
          </video>
        </div>
        <div className='flex flex-col justify-center items-center w-full mt-[20px] px-[10px] md:rounded-br-[200px]'>
          <span className='flg text-[2.6rem] sm:text-[1.6rem] font-bold text-center break-all'>
            <em>TRANSCODIFICACION</em>
            <p>PROCESAMIENTO <em>x</em>86_64</p>
          </span>
          <img src={logosCabecera1} alt="TRANSCODIFICACION" />
          <video playsInline muted autoPlay loop poster={x86_64Img} className="max-w-[80%] sm:max-w-full">
            <source src={x86_64} type="video/webm" />
          </video>
          <span className='flg text-[2.6rem] sm:text-[1.6rem] font-bold text-center'>
            <em>TRANSCODIFICACION</em>
            <p>PROCESAMIENTO ARM ACELERADO</p>
          </span>
          <img src={logosCabecera2} alt="TRANSCODIFICACION" />

          <video playsInline muted autoPlay loop poster={aceleradoImg} className="max-w-[80%] sm:max-w-full">
            <source src={acelerado} type="video/webm" />
          </video>
        </div>
        <div className='flex flex-col justify-center items-center w-full py-[50px] px-[10px]'>
          <span className='text-[2.6rem] sm:text-[2rem] font-bold text-center text-[#0794d9]'>TIPOS SERVICIO</span>
          <span className='flg text-[2.6rem] sm:text-[2rem] font-bold text-[#ffffff] text-center'><span>IpTV</span></span>
          <p className='flg text-justify max-w-[1400px] text-[#ffffff]'>En este modo operacional, el contenido pude ser enviado para su visualización a través de la red local FTTH, mediante utilización de modo MULTICAST o MULTICAST HTTP. En esta modalidad se debe disponer de recurso de red de acuerdo al incremento exponencial de La totalidad de los programas de televisión o programas de transmisión que componen la totalidad de la parrilla de programación ofrecida a los suscriptores que utilicen el servicio. Es decir, cada sesión de transmisión abierta del mismo origen multicast o mismo programa de televisión, representa el valor de ancho de banda único del programa transcodificado, dada la naturaleza del modo de transmisión MULTICAST. Nuestro proceso de transcodificación garantiza, la generación y optimización de recepción auto adaptativa, en los diferentes tipos de dispositivos que utilizan los servicios de transmisión de <span>IpTV</span> <span>OTT</span>, mediante redes FTTH o entornos WISP.</p>
          <span className='flg text-[2.6rem] sm:text-[2rem] font-bold text-[#ffffff] text-center mt-[80px]'><span>OTT</span></span>
          <p className='text-justify max-w-[1400px] text-[#ffffff]'>En este modo operacional, el contenido pude ser enviado para su visualización a través de Internet mediante utilización de modo UNICAST HTTP. En esta modalidad se debe disponer de recurso de red de acuerdo al incremento exponencial de los suscriptores que utilicen el servicio. Es decir, cada sesión de transmisión abierta, representa el valor de ancho de banda del programa transcodificado, dada la naturaleza del modo de transmisión UNICAST. Así, un servicio de un programa de televisión con un ancho de banda nominal de 6 Mbps, que este siendo visto por 1.000 suscriptores, demandaría del cable operador, una capacidad ascendente de 6 Mbps x 1.000 = 6.000 Mbps o 6 Gbps, lo cual representa una demanda de recursos altísima, que generalmente son solo cubiertos por proveedores de contenido, como HBO, DISNEY, NETFLIX, etc.</p>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default HeadboardPage