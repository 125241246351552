/**
 * @file TechnicalAssistancePage.tsx
 * @description Technical Assistance Page
 */

import Main from '../components/Main.tsx'
import Footer from '../components/Footer.tsx'
import Header from '../components/Header.tsx'
import { Formik, Form, Field } from 'formik'
import axios from 'axios'
import { useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup'
import React from 'react'

const TechnicalAssistancePage = (): JSX.Element => {
  const [isDisabled, setIsDisabled] = useState(false)
  const MySwal = withReactContent(Swal)
  return (
    <>
      <Header />
      <Main className="flex items-center justify-center min-h-[700px] bg-1 bg-no-repeat bg-cover">
        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '',
            company: '',
            message: '',
            phoneCode: '+57'
          }}
          validationSchema={
            yup.object().shape({
              name: yup.string()
                .min(2, 'No valido')
                .max(50, 'No valido')
                .required('Es requerido'),
              company: yup.string()
                .min(2, 'No valido')
                .max(50, 'No valido')
                .required('Es requerido'),
              phoneCode: yup.string()
                .required('Es requerido'),
              email: yup.string().matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/, 'No valido').required('Es requerido'),
              phone: yup.string().matches(/^[+]?[(]?[1-9]{1}[)]?[0-9]{2}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, 'No valido').required("Es requerido")
            })
          }
          onSubmit={async (values, actions) => {
            setIsDisabled(true)

            const config = {
              method: "post",
              url: "../server/mailControler.php",
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              data: {
                values,
                action: "asistencia"
              }
            }
            await axios(config)
              .then(res => {
                setIsDisabled(false)
                if (res.data.ok) {
                  MySwal.fire({
                    title: <strong>Se ha enviado correctamente</strong>,
                    icon: 'success',
                    iconColor: '#0794d9',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0794d9'
                  })
                }
                else if (res.data.err) {
                  console.error(res.data.err)
                  MySwal.fire({
                    title: <strong>Ha ocurrido un error</strong>,
                    text: res.data.err,
                    icon: 'error',
                    iconColor: '#c53030',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#c53030'
                  })
                }
              })
              .catch(err => {
                setIsDisabled(false)
                console.error(err)
                MySwal.fire({
                  title: <strong>Ha ocurrido un error</strong>,
                  text: 'Error Interno.',
                  icon: 'error',
                  iconColor: '#c53030',
                  confirmButtonText: 'Cerrar',
                  confirmButtonColor: '#c53030'
                })
              })

            actions.resetForm()
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className='sm:min-w-full min-h-[625px] min-w-[62px] rounded-[15px] border-[1px] border-[#272727] text-center p-[20px] flex justify-center flex-col gap-[20px] bg-[#000000] sm:gap-[5px] sm:py-[5px]'>
                <div><span className='text-[2rem] font-bold text-[#f2f2f2]'>Asistencia Técnica</span></div>
                <div className='grid grid-cols-2 sm:grid-cols-1 gap-[10px]'>
                  <div className='flex flex-col text-left gap-[5px]'>
                    <label className='font-bold text-[.9rem] text-[#ffffff]' htmlFor="name">Nombre *</label>
                    <Field type="text" className={(errors.name && touched.name ? "border-[#c53030] " : "") + 'input'} placeholder='Nombre' name='name' id="name" />
                    <label className={(errors.name && touched.name ? 'visible' : 'invisible') + ' font-bold text-[.9rem] text-[#c53030]'} htmlFor="name">{errors.name}&nbsp;</label>
                  </div>
                  <div className='flex flex-col text-left gap-[5px]'>
                    <label className='font-bold text-[.9rem] text-[#ffffff]' htmlFor="email">Email *</label>
                    <Field type="text" className={(errors.email && touched.email ? "border-[#c53030] " : "") + 'input'} placeholder='Correo Electrónico' name='email' id="email" />
                    <label className={(errors.email && touched.email ? 'visible' : 'invisible') + ' font-bold text-[.9rem] text-[#c53030]'} htmlFor="email">{errors.email}&nbsp;</label>
                  </div>
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-1 gap-[10px]'>
                  <div className='flex flex-col text-left gap-[5px]'>
                    <label className='font-bold text-[.9rem] text-[#ffffff]' htmlFor="phone">Teléfono *</label>
                    <div className='flex'>
                      <Field as="select" className={(errors.phoneCode && touched.phoneCode ? "border-[#c53030] " : "") + 'input'} name='phoneCode' id="phoneCode">
                        <option value="+57">CO(+57)</option>
                        <option value="+54">AR(+54)</option>
                        <option value="+591">BO(+591)</option>
                        <option value="+55">BR(+55)</option>
                        <option value="+593">EC(+593)</option>
                        <option value="+595">PY(+595)</option>
                        <option value="+51">PE(+51)</option>
                        <option value="+598">UY(+598)</option>
                        <option value="+58">VE(+58)</option>
                        <option value="+52">MX(+52)</option>
                        <option value="+1">CA(+1)</option>
                        <option value="+1">US(+1)</option>
                        <option value="+506">CR(+506)</option>
                        <option value="+53">CU(+53)</option>
                        <option value="+502">GT(+502)</option>
                        <option value="+504">HN(+504)</option>
                        <option value="+876">JM(+876)</option>
                        <option value="+505">NI(+505)</option>
                        <option value="+507">PA(+507)</option>
                        <option value="+1787">PR(+1787)</option>
                        <option value="+809">DM(+809)</option>
                        <option value="+34">ES(+34)</option>
                      </Field>
                      <Field type="text" className={(errors.phone && touched.phone ? "border-[#c53030] " : "") + 'input'} placeholder='Número de Teléfono' name='phone' id="phone" />
                    </div>
                    <label className={(errors.phone && touched.phone ? 'visible' : 'invisible') + ' font-bold text-[.9rem] text-[#c53030]'} htmlFor="phone">{errors.phone}&nbsp;</label>
                    <label className={(errors.phoneCode && touched.phoneCode ? 'visible' : 'invisible') + ' font-bold text-[.9rem] text-[#c53030]'} htmlFor="phoneCode">{errors.phoneCode}&nbsp;</label>
                  </div>
                  <div className='flex flex-col text-left gap-[5px]'>
                    <label className='font-bold text-[.9rem] text-[#ffffff]' htmlFor="company">Empresa *</label>
                    <Field type="text" className={(errors.company && touched.company ? "border-[#c53030] " : "") + 'input'} placeholder='Nombre Empresa' name='company' id="company" />
                    <label className={(errors.company && touched.company ? 'visible' : 'invisible') + ' font-bold text-[.9rem] text-[#c53030]'} htmlFor="company">{errors.company}&nbsp;</label>
                  </div>
                </div>
                <div className='flex flex-col text-left gap-[5px]'>
                  <label className='font-bold text-[.9rem] text-[#ffffff]' htmlFor="message">Mensaje</label>
                  <Field as="textarea" name="message" id="message" className='input h-[150px] resize-none py-[10px]' />
                </div>
                <div>
                  {
                    isDisabled
                      ?
                      <button type='submit' className='bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[64px] text-[1rem]' disabled>Cargando...</button>
                      :
                      <button type='submit' className='bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[64px] text-[1rem]'>Enviar</button>
                  }
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Main >
      <Footer page="Asistencia Técnica" />
    </>
  )
}

export default TechnicalAssistancePage